@-webkit-keyframes ngdialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ngdialog-fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes ngdialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ngdialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ngdialog {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.ngdialog *,
.ngdialog *:before,
.ngdialog *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.ngdialog {
  position: fixed;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.ngdialog.ngdialog-disabled-animation * {
  -webkit-animation: none !important;
  animation: none !important;
}

.ngdialog-overlay {
  position: fixed;
  /*background: rgba(0, 0, 0, 0.4);*/
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadein 0.2s;
  animation: ngdialog-fadein 0.2s;
}

.ngdialog.ngdialog-closing .ngdialog-overlay {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadeout 0.2s;
  animation: ngdialog-fadeout 0.2s;
}

.ngdialog-content {
  background: white;
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadein 0.2s;
  animation: ngdialog-fadein 0.2s;
}

.ngdialog.ngdialog-closing .ngdialog-content {
  -webkit-backface-visibility: hidden;
  -webkit-animation: ngdialog-fadeout 0.2s;
  animation: ngdialog-fadeout 0.2s;
}

/*.ngdialog-close:before {
  font-family: 'Helvetica', Arial, sans-serif;
  content: '\00D7';
  cursor: pointer;
}*/

body.ngdialog-open {
  overflow: hidden;
}
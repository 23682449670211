@font-face {
  font-family: "iconfont";
  src: url($url + '/common/static/font/iconfont/iconfont.eot'); /* IE9*/
  src: url($url + '/common/static/font/iconfont/iconfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url($url + '/common/static/font/iconfont/iconfont.woff') format('woff'), /* chrome、firefox */
  url($url + '/common/static/font/iconfont/iconfont.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url($url + '/common/static/font/iconfont/iconfont.svg#iconfont') format('svg'); /* iOS 4.1- */
}

.iconfont {
  font-family:"iconfont" !important;
  font-size:16px;
  font-style:normal;
  // -webkit-font-smoothing: antialiased;
  // -webkit-text-stroke-width: 0.2px;
  // -moz-osx-font-smoothing: grayscale;
}

$sort-bg: $url + '/common/static/img/sort_bg.png';
$openlist: $url + '/common/static/img/list_open_close.png';
$arrow-bg: $url + '/common/static/img/arrow_b.png';
$arrow-default: $url + '/common/static/img/arrow_default.png';

.icon-dingdanguanli:before { content: "\e603"; }
.icon-dingjiaguanli:before { content: "\e604"; }
.icon-huiyuanguanli:before { content: "\e605"; }
.icon-jichuxinxiguanli:before { content: "\e606"; }
.icon-shangpinguanli:before { content: "\e607"; }
.icon-shouye:before { content: "\e608"; }
.icon-xitongguanli:before { content: "\e609"; }
.icon-xiaoshoubaobiao:before { content: "\e60a"; }
.icon-bangzhu:before { content: "\e602"; }
.icon-tuichu:before { content: "\e60b"; }
.icon-yijianfankui:before { content: "\e60c"; }
.icon-zhanghu:before { content: "\e60d"; }
.icon-caidanguanli:before { content: "\e613"; }
.icon-jiaoseguanli:before { content: "\e614"; }
.icon-quanxianguanli:before { content: "\e615"; }
.icon-yonghuguanli:before { content: "\e616"; }
.icon-zuzhiguanli:before { content: "\e617"; }
.icon-guanbiicon:before { content: "\e60e"; }
.icon-jiantouxia:before { content: "\e60f"; }
.icon-jiantouyou:before { content: "\e610"; }
.icon-shuaxinicon:before { content: "\e611"; }
.icon-sousuoicon:before { content: "\e612"; }
.icon-tianjia:before { content: "\e618"; }
.icon-jiantou2:before { content: "\e619"; }
.icon-shanchu:before { content: "\e600"; }
.icon-bianjixiugai:before { content: "\e601"; }
.icon-shangzhangjiantou:before { content: "\e61a"; }
.icon-xiadiejiantou:before { content: "\e61b"; }
.icon-shujumofang:before { content: "\e61c"; }
.icon-yingxiao:before { content: "\e61d"; }
.icon-wenhao:before { content: "\e61f"; }
.icon-rili:before { content: "\e61e"; }
.icon-dianyingyunwenzi:before { content: "\d002"; }
.icon-dianyingyunlogo:before { content: "\d001"; }
.icon-dingjia:before { content: "\e620"; }
.icon-huiyuan:before { content: "\e621"; }
.icon-mima:before { content: "\e622"; }
.icon-paipian:before { content: "\e623"; }
.icon-shujuluopan:before { content: "\e624"; }
.icon-yingxiao1:before { content: "\e625"; }
.icon-yonghuming:before { content: "\e626"; }
.icon-qudaoguankong:before { content: "\e627"; }
// 2016-03-25
.icon-dianyingyunlogoheng:before { content: "\e629"; }
.icon-huidaodingbu:before { content: "\e62a"; }

.icon-ACUSER:before {content: "\e616";}
.icon-ACROLE:before {content: "\e614";}
.icon-ACMENU:before {content: "\e613";}
.icon-ACRIGHTS:before {content: "\e615";}

.icon-MCINDEX:before {content: '\e608';}
.icon-MCPRICING:before {content: '\e604';}
.icon-MCBASEDATA:before {content: '\e606';}
.icon-MCSCHEDULE:before {content: '\e607';}
.icon-MCORDER:before {content: '\e603';}
.icon-MCANALYSIS:before {content: '\e60a';}
.icon-MCVIP:before {content: '\e605';}
.icon-MCSYSTEM:before {content: '\e609';}
.icon-MCDATAPROD:before {content: '\e61c';}
.icon-MCPROMO:before{content: '\e61d';}

.icon-xinxi:before { content: "\e62c"; }
.icon-fanhui:before { content: "\e62d"; }
.icon-shangyiye:before { content: "\e62e"; }
.icon-xiayiye:before { content: "\e62f"; }

// other icon
.icon-sort {
    display: inline-block;
    width: 7px;
    height: 10px;
    background-image: url("${sort-bg}");
    background-repeat: no-repeat;
}
.icon-sort-up {
    background-position: -10px 0;
}
.icon-sort-down {
    background-position: -20px 0;
}
.icon-sort-default {
    background-position: 0 0;
}
.icon-list {
    display: inline-block;
    // vertical-align: top;
    // margin-top: 12px;
    width: 16px;
    height: 16px;
    background-image: url($openlist);
    background-repeat: no-repeat;
    margin-top: 2px;
    margin-right: 8px;
    vertical-align: middle;
}
.icon-list-open {
    background-position: 0 0;
}
.icon-list-close {
    background-position: 0 -17px;
}

.icon-shanchu {
    color: #ff5151;
}

.my-icon {
    display: inline-block;
    // margin-right: 4px;
}
.icon-chevron-top {
    background-image: url($arrow-bg);
    background-repeat: no-repeat;
    background-position: 4px 6px;
    width: 16px;
    height: 16px;
}
.icon-chevron-down {
    background-image: url($arrow-bg);
    background-repeat: no-repeat;
    background-position: 4px -17px;
    width: 16px;
    height: 16px;
}
.icon-chevron-default {
    background-image: url($arrow-default);
    background-repeat: no-repeat;
    background-position: 4px -37px;
    width: 16px;
    height: 16px;
}

.icon-circle {
    display: inline-block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    &.red {
      background-color: #fd5041;
    }
    &.green {
      background-color: #02bd4b;
    }
}

.icon-code {
    background-image: url($url + "/common/static/img/code.png");
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
}
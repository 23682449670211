// $url: "//192.168.0.86:9010";
$url: "//cdn.yuekeyun.com";
// @import "../../../common/common/static/css/iconfont/iconfont";
@import "../../../common/static/sass/iconfont";
@import "../../../common/static/js/plugin/ngdialog/ngDialog";
@import "../../../common/static/js/plugin/ngdialog/ngDialog-theme-default";
body {
    padding: 0;
    margin: 0;
}
ul {
    margin: 0;
    li {
        list-style: none;
    }
}
.login-container {
    color: #999;
    .login-wrapper {
        position: absolute;
        background-image: url($url + "/common/static/img/login/loginBg.jpg");
        background-repeat: no-repeat;
        background-position: center bottom;
        width: 100%;
        height: 100%;
        .login-section {
            position: absolute;
            top: 40%;
            left: 50%;
            margin-left: -200px;
            background-image: url($url + "/common/static/img/login/formBg.png");
            background-repeat: repeat;
            border-radius: 10px;
            z-index: 99;
            // width: 400px;
            // max-width: 25vmin;
            .logo {
                margin: 40px auto 0;
                background-image: url($url + "/common/static/img/login/loginLogo.png");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 100%;
                width: 184px;
                height: 137px;
            }

            .form-login {
                position: relative;
                padding: 40px 0;
                margin: 0 40px;
                // padding: 2.5vmin;
                input {
                    padding: 8px;
                    width: 260px;
                    height: 32px;
                    font-size: 14px;
                    border: none;
                    background-color: transparent!important;
                    color: #fff!important;
                    &:focus {
                        outline: none;
                    }
                }
                .username-wrapper,
                .password-wrapper {
                    background-image: url($url + "/common/static/img/login/inputBg.jpg");
                    background-repeat: repeat;
                    height: 50px;
                    .iconfont {
                        position: relative;
                        top: 4px;
                        color: #fff;
                        font-size: 24px;
                        margin-left: 11px;
                    }
                }
                .username-wrapper {
                    border-radius: 4px 4px 0 0;
                }
                .password-wrapper {
                    border-radius: 0 0 4px 4px;
                }
                .verify-wrapper {
                    margin-top: 16px;
                    input {
                        display: inline-block;
                        margin-right: 8px;
                        vertical-align: top;
                        border-radius: 4px;
                        background-image: url($url + "/common/static/img/login/inputBg.jpg");
                        background-repeat: repeat;
                        width: 188px;
                        height: 34px;
                    }
                    .captcha {
                        display: inline-block;
                        width: 100px;
                        height: 50px;
                        background-color: #fff;
                        border-radius: 4px;
                    }
                }
                .seq {
                    position: absolute;
                    //width: 298px;
                    width: 100%;
                    height: 1px;
                    background-color: #4c7da5;
                    //margin: 0 11px;
                }
                .btn-wrapper {
                    margin-top: 16px;
                    button {
                        background-color: #009dee;
                        line-height: 50px;
                        font-size: 18px;
                        color: #fff;
                        border-radius: 6px;
                        width: 100%;
                        border: none;
                        font-family: "Microsoft YaHei";
                        cursor: pointer;
                        outline: none;
                        &:hover,
                        &:active {
                            background-color: #0086cb;
                        }
                        &:active {
                            box-shadow: 0 2px 1px rgba(0,0,0,.2) inset;
                        }
                        &:disabled {
                            background-color: #ddd;
                            cursor: default;
                        }
                    }
                }

                .tips-wrapper {
                    text-align: left;
                    color: #ff6666;
                    font-size: 12px;
                    //margin: 16px 8px 0;
                    width: 316px;
                }
            }
        }
    }
    .login-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        font-size: 12px;
        vertical-align: middle;
        .footer1 {
            background-color: #fff;
            max-height: 131px;
            ul {
                padding: 34px 0;
                margin: 0;
                li {
                    display: inline-block;
                    margin-bottom: 8px;
                    .iconfont {
                        display: inline-block;
                        font-size: 50px;
                        vertical-align: top;
                        color: #bbb;
                    }
                    .footer-content {
                        display: inline-block;
                        width: 188px;
                        text-align: left;
                        vertical-align: top;
                        h4 {
                            margin: 0;
                            font-size: 18px;
                            font-weight: normal;
                            font-family: "Microsoft YaHei";
                            color: #666;
                        }
                        p {
                            margin: 0;
                            line-height: 20px;
                            color: #999;
                        }
                    }
                    &:nth-child(3) {
                        margin: 0;
                    }
                }
            }
        }
        .footer2 {
            background-color: #eee;
            // height: 36px;
            padding: 8px 0 8px;
            line-height: 24px;
            p {
                display: inline-block;
                margin: 0;
                a {
                    color: #999;
                    text-decoration: none;
                }
            }
        }
    }
    .change-pass-action {
        color: #fff;
        text-align: right;
        font-size: 12px;
        float: right;
        padding-bottom: 10px;
        margin-right: 40px;
    }
}
@media screen and (min-width: 320px) and (max-width: 500px) {
    .login-container {
        .logo {
            margin-top: 8px!important;
            width: 120px!important;
            height: 120px!important;
        }
        .login-section {
            top: 50%!important;
            margin-left: -155px!important;
            width: 310px!important;
            .form-login {
                padding: 8px!important;
                margin: 0!important;
                input {
                    width: 234px!important;
                }
                .verify-wrapper {
                    input {
                        width: 162px!important;
                    }
                }
                .seq {
                    width: 274px!important;
                }
            }
        }
        .login-footer {
            .footer1 {
                min-height: auto!important;
                ul {
                    padding: 8px 0;
                    li {
                        .iconfont {
                            font-size: 36px;
                        }
                        .footer-content {
                            width: auto!important;
                            h4 {
                                font-size: 14px;
                            }
                            p {
                                width: 50px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
            .footer2 {
                line-height: inherit;
                height: auto;
                padding: 6px;
            }
        }
    }
}

@media screen and (max-width: 404px) {
    .login-container {
        .login-footer {
            .footer1 {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 745px) and (max-width: 1001px) {
    .footer1 {
        ul {
            li {
                &:nth-child(3) {
                    display: block!important;
                }
            }
        }
    }
}

@media screen and (max-width: 1001px) {
    .footer1 {
        ul {
            padding: 8px 0!important;
            li {
                h4 {
                    font-size: 14px!important;
                }
                p {
                    line-height: inherit!important;
                }
            }
        }
    }
}

@media screen and (max-height: 660px) {
    .login-container {
        .logo {
            margin-top: 0!important;
            width: 120px!important;
            height: 120px!important;
        }
        .form-login {
            padding: 20px!important;
            input {
                height: 24px!important;
            }
            .username-wrapper,
            .password-wrapper {
                height: 40px!important;
            }
            .verify-wrapper {
                margin-top: 8px!important;
            }
            .tips-wrapper {
                margin: 8px!important;
            }
            .captcha {
                height: 40px!important;
            }
            .btn-wrapper {
                margin-top: 8px!important;
                button {
                    line-height: 40px!important;
                }
            }
        }
        .login-footer {
            .footer1 {
                ul {
                    padding: 8px 0!important;
                }
            }
        }
    }
}

.showtime-offline {
    .ngdialog-overlay {
        display: none;
    }
    .ngdialog-content {
        padding: 24px;
        width: 716px;
        margin: 160px auto;
        letter-spacing: 3px;
    }
    .dialog-body {
        font-size: 16px;
        color: #999;
        box-sizing: border-box;
        overflow-y: hidden;
        max-height: 568px;
        a {
            text-decoration: none;
            color: #009dee;
        }
        strong {
            color: #666;
        }
        .notice-body {
            text-indent: 40px;
            line-height: 32px;
        }
        .notice-bottom {
            text-align: right;
        }
    }
    .dialog-footer {
        text-align: right;
        button {
            background-color: #009dee;
            line-height: 30px;
            width: 80px;
            font-size: 14px;
            color: #fff;
            border-radius: 6px;
            border: none;
            cursor: pointer;
        }
    }
}